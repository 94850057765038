@import "../../../globalStyle/colours.scss";

/******************************************************************* 
Animations must be global file 
********************************************************************/

// .skill-card-enter {
//   opacity: 0;
//   transition: 1s;
//   transform: translateX(220%);

//   &.skill-card-enter-active {
//     transition: 1s;
//     transform: translateX(0%);
//     opacity: 1;
//   }
// }

// .skill-card-exit {
//   opacity: 1;
//   transition: 0.5s;
//   transform: translateX(0);
//   &.skill-card-exit-active {
//     transition: 0.5s;
//     transform: translateX(220%);
//     opacity: 0.01;
//   }
// }

// @media only screen and (max-width: 900px) {
//   .skill-card-enter {
//     opacity: 0;
//     transition: 1s;
//     transform: unset;
//     &.skill-card-enter-active {
//       transition: 1s;
//       opacity: 1;
//       transform: unset;
//     }
//   }

//   .skill-card-exit {
//     opacity: 1;
//     transition: 0.5s;
//     transform: unset;
//     &.skill-card-exit-active {
//       transition: 0.5s;
//       opacity: 0.01;
//       transform: unset;
//     }
//   }
// }

/******************************************************************* 
New Skill Card animations
********************************************************************/

.skillCard {
  --inline: 10px;
  --block: 0;
  --duration: 150ms;
  --padding: 3px;
  --border: 1px;
  --radius: 10px;
  background-color: $base-background-grey;
  box-shadow: inset 0 0 0 var(--border) $secondary-font-color;
  max-width: 100%;
  width: 100%;
  padding: var(--padding);
  border-radius: calc(var(--radius) + var(--padding));
  overflow: hidden;
  display: flex;
}

.inner {
  background-color: $base-background-color;
  z-index: 2;
  padding: 10px;
  border-radius: var(--radius);
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.tail {
  transition: all var(--duration);
  width: var(--inline);
  height: var(--block);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.tail.top {
  height: var(--inline);
  width: var(--block);
  transition-delay: var(--duration);
}

.tail.right {
  right: 0;
  left: unset;
  transition-delay: calc(var(--duration) * 2);
}

.tail.bottom {
  height: var(--inline);
  width: var(--block);
  bottom: 0;
  right: 0;
  left: unset;
  top: unset;
  transition-delay: calc(var(--duration) * 3);
}

.tail.left {
  bottom: 0;
  top: unset;
  transition-delay: 0ms;
}

.snake:where(.start, :hover) .tail.top {
  transition-delay: var(--duration);
}
.snake:where(.start, :hover) .tail.right {
  transition-delay: calc(var(--duration) * 2);
}
.snake:where(.start, :hover) .tail.bottom {
  transition-delay: calc(var(--duration) * 3);
}
.snake:where(.start, :hover) .tail.left {
  transition-delay: 0ms;
}

.snake {
  position: relative;
}

.snake.start .tail {
  --block: 0;
}
.snake:hover .tail {
  --block: 100%;
}

