@import "../../../globalStyle/colours.scss";

.pageContainerSkills {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  padding: 5vh 5vw 0 5vw;
  width: 100%;
  max-width: 84vw;
  // max-width: calc(100% - 16vw)
}

.skillsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5vw;
  padding: 2vh 0;
}

.skillsTitle {
  color: $base-hover-color;
  font-weight: 500;
  font-size: clamp(4rem, 3.25rem + 3.3333vw, 5rem);
  margin: 0;

  @media only screen and (max-width: 1200px) {
      text-align: center;
  }
}

.revealedSkillsContainer {
  display: flex;
  color: $secondary-font-color;
  width: 100%;
  max-width: 70%;
  position: relative;
  height: 100%;
  padding: 0 1vw;
}

.selectSkillsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 30%;
  justify-content: flex-start;
  height: 100%;
}

.selectSkills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.aboutMySkills {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 25px 0 25px 0;
  width: 100%;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
  }
}

.revealSkills {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selectedSkillset {
  color: $base-hover-color;
  font-weight: bold;
  text-align: center;
  width: 100%;
  max-height: 100%;

  ul {
    padding: 0 0 5vh 0;
    margin: 0;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    gap: 35px;

    li {
      width: 100%;
      height: 100%;
      display: flex;

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
}

.aboutMySkillsButton {
  justify-self: start;
}

.clickedBtn {
  color: $base-background-color;
  background-color: $base-hover-color;
}

.skillsBlurb {
  color: $base-font-color;
  width: 100%;

  @media only screen and (max-width: 1200px) {
      text-align: center;
  }
}

.contactLink {
  color: $base-hover-color;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .pageContainerSkills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    height: auto;
    max-width: 90vw;
    width: 100%;
    margin: 0;
    padding: 10vh 5vw 5vh 5vw;
  }
  .skillsContainer {
    flex-direction: column;
    position: relative;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .skillsTitle {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    font-size: clamp(2.5rem, 2rem + 2.5vw, 3rem);
  }

  .skillsBlurb {
    text-align: left;
  }

  .revealedSkillsContainer {
    margin: 0;
    max-width: 100%;
    width: 100%;
    display: inline-block;
    padding: 20px 0;
    height: 100%;
    overflow-y: visible;
  }

  .selectSkillsContainer {
    margin: 0;
    max-width: 100%;
    width: 100%;
    padding-top: 20px;
    display: inline-block;
  }

  .selectSkills {
    height: unset;
  }

  .aboutMySkills {
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0 20px 0;
  }

  .aboutComponent {
    flex-direction: column;
    margin: 0;
    align-content: center;
  }

  .selectedSkillset {
    margin: 0;

    ul {
      gap: 20px;
    }
  }

  .aboutMySkillsButton {
    justify-self: center;
  }
}
