.preLoopQuote {
    p {
    margin: 0;
    color: white;
    font-size: 8vw;
    position: relative;
    z-index: 9;
    }
}

.quoteLoop {
    color: white;
}

// @media only screen and (max-width: 1100px) {
//     .preLoopQuote {
//         p {
//         font-size: 6vw;
//         }
//     }
//   }
  
//   @media only screen and (max-width: 600px) {
//     .preLoopQuote {
//         p {
//         font-size: 10vw;
//         }
//     }
//   }
  


