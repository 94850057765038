@import "../../../globalStyle/colours.scss";

.pageContainerScrollWork {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: calc(100% - (10vw));
}

.scrollText {
  font-size: 2.5rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .scrollText {
    margin: 0;
    font-size: 1rem;
  }
}

.workContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

// Needed to override parallax styles
.workParallaxContainer {
  width: 94% !important;

    @media (max-width: 900px) {
      width: 100% !important;
    }
}

@media (max-width: 900px) {
  .parallax {
    margin-right: 1.5rem;
  }

  .sticky {
    margin-left: 0.5rem;
    font-size: 12px;
  }
}

.stickyCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 5rem;
  max-height: 10rem;
  max-width: 25%;
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .pageContainerScrollWork {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: unset;
    position: fixed;
    display: inline-block;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .stickyCard {
    max-width: 15%;
  }
}

.workTitle {
  display: flex;
  color: $base-hover-color;
  justify-content: center;
  text-align: center;
  width: 100%;
  align-self: center;
  font-weight: 500;
  font-size: clamp(2rem, -0.25rem + 10vw, 5rem);
}

@media only screen and (max-width: 900px) {
  .workTitle {
    display: flex;
    align-items: flex-start;
  }
}

.workPageArrow {
  padding-top: 20px;
  -webkit-animation: arrowAnimation 2s ease-in-out infinite;
}

@keyframes arrowAnimation {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.stickyCardImage {
  display: flex;
  width: 100%;
  max-width: 210px;
  height: auto;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .stickyCard {
    max-width: 15%;
  }

  .stickyCardImage {
    max-width: 150px;
  }
}

@media only screen and (max-width: 600px) {
  .stickyCard {
    max-width: 12%;
  }
}
