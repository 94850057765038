@import "../../../globalStyle/colours.scss";

.contactPageContainer {
  display: flex;
  justify-content: center;
  padding: 5vh 5vw;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 10vw);
}

.topBuffer {
  display: none;
}

.contactComponent {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
}

.contactTitle {
  color: $base-hover-color;
  display: flex;
  justify-content: flex-start;
  font-weight: 500;
  font-size: clamp(4rem, 3.25rem + 3.3333vw, 5rem);
  margin: 0;
}

.contactBlurb {
  color: $base-font-color;
  padding: 2vh 0 4vh 0;
  margin: 0;
}

.contactForm {
  display: flex;
  flex-direction: column;
}

.contactFormUl {
  list-style: none;
  margin: 0;
  padding: 0;

  textarea,
  input,
  select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;

    &:focus {
      outline: $base-hover-color solid 1px;
      border: $base-hover-color solid 1px;
      border-radius: 8px;
      -webkit-border-radius: 8px;
      outline: none;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus, 
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 40rem $base-background-color inset;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  outline: none;
}

.redWarning {
  border: $mar solid 1px !important;
  outline: unset !important;
  animation: redGlowWarning 1s 6 alternate ease-in-out;
}

@keyframes redGlowWarning {
  from {
    box-shadow: 0 0 1px -1px $mar;
  }
  to {
    box-shadow: 0 0 5px 2px $mar;
  }
}

.emailWarning {
  border: $mellowYellow solid 1px !important;
  outline: unset !important;
  animation: emailGlowWarning 1s 6 alternate ease-in-out;
}

@keyframes emailGlowWarning {
  from {
    box-shadow: 0 0 1px -1px $mellowYellow;
  }
  to {
    box-shadow: 0 0 5px 2px $mellowYellow;
  }
}

.nameAndEmail {
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  padding-bottom: 20px;
}

.nameInput {
  width: 100%;
  height: 100%;
  background: $base-background-color;
  border: $secondary-font-color solid 1px;
  text-indent: 20px;
  color: white;
  border-radius: 8px;
}

.emailInput {
  width: 100%;
  height: 100%;
  background: $base-background-color;
  border: $secondary-font-color solid 1px;
  text-indent: 20px;
  color: white;
  border-radius: 8px;
}

.subjectInput {
  width: 100%;
  height: 100%;
  background: $base-background-color;
  border: $secondary-font-color solid 1px;
  text-indent: 20px;
  --webkit-text-indent: 20px;
  color: white;
  border-radius: 8px;
  appearance: unset;
}

@-moz-document url-prefix() {
  .subjectInput {
  text-indent: 10px;
  }
}

.messageInput {
  width: 100%;
  height: 150px;
  background: $base-background-color;
  border: $secondary-font-color solid 1px;
  text-indent: 20px;
  padding-top: 20px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  border-radius: 8px;
}

.name {
  width: 100%;
}

.email {
  width: 100%;
  padding: 0 0 0 20px;
}

.subject {
  width: 100%;
  height: 50px;
  padding: 0 0 20px 0;
}

.message {
  width: 100%;
  padding: 0 0 4vh 0;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 2vh 0;
}

@media only screen and (max-width: 900px) {
  .contactPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: unset;
    width: auto;
    height: auto;
    padding: 10vh 5vw 5vh 5vw;
    margin: 0;
  }

  .contactTitle {
    display: flex;
    justify-content: start;
    font-size: clamp(2.5rem, 2rem + 2.5vw, 3rem);
  }

  .topBuffer {
    height: 100px;
    width: 100%;
  }

  .contactComponent {
    margin: 0;
    padding: 0;
  }

  .nameAndEmail {
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
  }

  .name {
    width: 100%;
    padding-right: 20px;
    height: 50px;
  }

  .email {
    width: 100%;
    padding: 0 20px 0 0;
    height: 50px;
  }
}
