@import "../globalStyle/colours.scss";

/******************************************************************* 
Layout Styling
********************************************************************/
html {
  background-color: $base-background-color;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif, "General Sans", "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", apple-color-emoji, "Segoe UI Emoji", "Noto Color Emoji",
    "Android Emoji", "EmojiSymbols", "EmojiOne Mozilla", "Twemoji Mozilla",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  letter-spacing: 1.8px;
  line-height: 1.2;
  font-size: clamp(0.75rem, 0.1875rem + 2.5vw, 1.5rem);
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  word-break: break-word;
  overflow-wrap: break-word;
}

#root {
  width: calc(100% - 100px);
  margin-left: 100px;
  height: 100%;

  @media only screen and (max-width: 900px) {
    margin: 0;
    padding: 0;
    max-width: 100%;
    width: 100%;
  }
}

main {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}

/******************************************************************* 
Toastify Overrides
********************************************************************/

.Toastify__toast--error {
  background: $mar !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
}

.Toastify__toast--warning {
  background: $mellowYellow !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
}

.Toastify__toast--success {
  background: $warmGreen !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
}

.Toastify__toast--info {
  background: $warmGreen !important;
  font-size: 16px !important;
  letter-spacing: normal !important;
}

/******************************************************************* 
Basic Page Containers
********************************************************************/

.page-container-fixed {
  display: flex;
  justify-content: flex-start;
  margin: 0 40px 0 100px;
  position: fixed;
  width: 100%;
  min-height: 100vh;
}

@media only screen and (max-width: 900px) {
  .page-container-fixed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: unset;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.page-container-scroll {
  display: flex;
  justify-content: flex-start;
  margin: 0 40px 0 100px;
  width: 100%;
  height: 100vh;
}

@media only screen and (max-width: 900px) {
  .page-container-scroll {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

/******************************************************************* 
Mobile Nav
********************************************************************/

.mobile-nav {
  visibility: hidden;
}

@media only screen and (max-width: 900px) {
  .mobile-nav {
    visibility: visible;
  }
}

.eidOtH a:hover {
  color: $base-hover-color;
}

.mobile-nav > button {
  outline: none;
  position: fixed;
}

/******************************************************************* 
Global Contact Style Btn
********************************************************************/

.default-btn {
  color: $base-hover-color;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 4px;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid $base-hover-color;
  // margin-top: 25px;
  width: 175px;
  text-align: center;
  font-weight: 500;
  background-color: transparent;

  &:hover {
    background-color: $base-hover-color;
    color: $base-background-color;
    border-color: $base-hover-color;
  }
}

// @media only screen and (max-width: 900px) {
//   .default-btn {
//     margin-top: 25px;
//   }
// }
