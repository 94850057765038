@import "../../../globalStyle/colours.scss";

.pageContainerAbout {
  display: flex;
  padding: 5vh 5vw;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 10vw);
  flex-wrap: wrap;
}

.aboutMeContainer {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  width: 100%;
  height: 100%;
  justify-content: start;
}

.aboutMeSubContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  gap: 2.5%;
}

.aboutTitle {
  color: $base-hover-color;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-weight: 500;
  font-size: clamp(4rem, 3.25rem + 3.3333vw, 5rem);
  margin: 0;
}

.aboutSubTitle {
  color: $base-font-color;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-weight: 500;
  font-size: clamp(2rem, 1.25rem + 3.3333vw, 3rem);
  margin: 1.5vh 0;
}

.aboutBtnInfoContainer {
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.aboutMeBlurb {
  color: white;
  width: 100%;
  margin: 1vh 0;

  strong {
    font-size: 1.5em;
  }
}

.aboutmeEduList {
  color: white;
  width: 100%;
  margin: 1vh 0;
}

.workExperienceContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: start;
  align-items: center;
}

.textLink {
  color: $base-hover-color;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .pageContainerAbout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    position: unset;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 10vh 5vw 5vh 5vw;
  }

  .aboutMeSubContainer {
    flex-direction: column;
    height: auto;
    padding: 0 0 0 0;
    width: 100%;
  }

  .aboutMeContainer {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .aboutTitle {
    justify-content: start;
    font-size: clamp(2.5rem, 2rem + 2.5vw, 3rem);
  }

  .aboutSubTitle {
    font-size: clamp(1.5rem, 1rem + 2vw, 2rem); 
  }

  .aboutBtnInfoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: unset;
    margin-bottom: 40px;
    height: 250px;
    position: relative;
  }

  .aboutMeBlurb {
    text-align: left;
  }

  .aboutmeEduList {
    list-style-type: none;
    color: white;
    width: 100%;
    margin: 1vh 0;
    padding: 0 0 2vh 0;

    li {
      padding: 0.5vh 0;
    }
  }

  .workExperienceContainer {
    width: 100%;
    padding: 0;
  }

  .workExpCards {
    padding-left: 0;
    width: 95%;
  }
}
