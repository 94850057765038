@import "../../globalStyle/colours.scss";

.navBar {
  display: flex;
  color: $base-font-color;
  top: 0;
  left: 0;
  height: 100%;
  width: 100px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  background-color: transparent;
  backdrop-filter: blur(10px);
  border-right: $secondary-font-color solid 0.5px;
  z-index: 100;
  overflow-y: scroll;
}

@media only screen and (max-width: 900px) {
  .navBar {
    background-color: $base-background-color;
    display: none;
  }
}

.myName {
  font-size: 2rem;
  line-height: 1;
  color: $base-hover-color;
  margin-top: 50px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;
  font-size: 17px;
  letter-spacing: 2px;
  width: 100%;
  text-align: center;

  li:hover {
    color: $base-hover-color;
    cursor: pointer;
  }

  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    fill: $base-hover-color;

    svg {
      display: var(--fa-display, inline-block);
      height: 1em;
      overflow: visible;
      vertical-align: -0.125em;
    }
  }

  li:hover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    fill: $base-hover-color;
    text-decoration: none;

    &:hover {
      .homeIcon,
      .aboutIcon {
        display: none;
      }

      .skillsIcon {
        color: white;
        text-decoration: none;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        position: absolute;
        animation: fadeOut 0.8s 1;
        animation-timing-function: ease-out;

        @keyframes fadeOut {
          from {
            opacity: 1;
          }
          to {
            opacity: 0;
          }
        }
        opacity: 0;
      }

      .workIcon {
        width: 100px;
        position: absolute;
        animation: crankIt 0.5s 1;

        @keyframes crankIt {
          0% {
            transform: rotate(0deg);
            opacity: 1;
          }
          25% {
            transform: rotate(25deg);
          }
          100% {
            transform: rotate(0deg);
            opacity: 0;
          }
        }
        opacity: 0;
      }

      .servicesIcon {
        width: 100px;
        position: absolute;
        animation: spinOut 0.5s 1;
        animation-timing-function: ease-out;

        @keyframes spinOut {
          from {
            transform: rotate(0deg);
            opacity: 1;
          }
          to {
            transform: rotate(360deg);
            opacity: 0;
          }
        }
        opacity: 0;
      }

      .contactIcon {
        width: 100px;
        position: absolute;
        animation: sentOut 0.5s 1;
        animation-timing-function: ease-out;

        @keyframes sentOut {
          from {
            left: 0px;
            opacity: 1;
          }
          to {
            left: 100px;
            opacity: 0;
          }
        }
        opacity: 0;
      }
    }
  }

  .home:hover::after {
    visibility: visible;
    content: "HOME";
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: $base-hover-color;
    text-decoration: none;
  }

  .about:hover::after {
    visibility: visible;
    content: "ABOUT";
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: $base-hover-color;
    text-decoration: none;
  }

  .services:hover::after {
    visibility: visible;
    content: "SERVICES";
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: $base-hover-color;
    animation: fadeIn;
    animation-duration: 1.2s;
    animation-iteration-count: 1;
    text-decoration: none;
  }

  .skills:hover::after {
    visibility: visible;
    content: "SKILLS";
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: $base-hover-color;
    animation: fadeIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
    text-decoration: none;
  }

  .work:hover::after {
    visibility: visible;
    content: "WORK";
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: $base-hover-color;
    animation: fadeIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
    text-decoration: none;
  }

  .contact:hover::after {
    visibility: visible;
    content: "CONTACT";
    font-size: 11px;
    display: flex;
    justify-content: center;
    color: $base-hover-color;
    animation: fadeIn;
    animation-duration: 1s;
    animation-iteration-count: 1;
    text-decoration: none;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.socialMedia {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-inline-start: 0;
  width: 100%;

  li {
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    justify-content: center;
    font-size: 11px;
    letter-spacing: 2px;
    text-align: center;
    height: 40px;
    align-items: center;

    a {
      display: flex;
      color: white;
      text-decoration: none;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 17px;

      &:hover {
        color: $base-hover-color;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .socialMedia > li > a:hover {
    color: $base-hover-color;
    cursor: pointer;
  }
}

.navBar {
  color: $base-hover-color;
  text-decoration: none;
}

.navBar > li {
  color: $base-hover-color;
  text-decoration: none;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

ul.menu > li {
  color: white;
  text-decoration: none;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.menu:active {
  text-decoration: none;
}

ion-icon {
  color: white;
}
