// Global colour variables
$base-background-color:  black;
$nav-background-color: #191919;
$base-background-grey: #1b1b1b; 
$secondary-background-black: #2b2b2b;
$base-font-color: white;
$base-hover-color: #00fff0;
$secondary-font-color: #8d8d8d;
$mellowYellow: #F6BE00;
$mar: maroon;
$warmGreen: #198C4B;