@import "../../../globalStyle/colours.scss";

.skillImage {
  display: flex;
  height: 50px;
  font-size: 12px;
  align-items: center;
}

:global(.inverseWhite) {
  filter: invert(1);
}

.skillCards {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
